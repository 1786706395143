import services from "../axios/dbManag";
import store from "../store/index";

const token = store.getters.tokenAdb2c
  ? store.getters.tokenAdb2c
  : localStorage.getItem("tokenAdb2c");

export const addPagamento = (pagamento) => {
  return services.dbManag
    .post("/economato/movimenti/pagamento/add", {
      ...pagamento,
      return_url: window.location.href,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addMovimentoManuale = (movimento) => {
  return services.dbManag
    .post("/economato/societa/movimenti/manuali/add", {
      ...movimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const approvaMovimentiMultipla = (movimenti) => {
  return services.dbManag
    .post("/economato/movimenti/approvazione/multipla", {
      ...movimenti,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const approvaMovimentiMultiplaDaPagamento = (movimenti) => {
  return services.dbManag
    .post("/economato/movimenti/approvazione/multipla/da-pagamento", {
      ...movimenti,
      return_url: window.location.href,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getMovimentiImporto = (movimenti) => {
  return services.dbManag
    .post("/economato/movimenti/totimporto/list", {
      movimenti: movimenti,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

export const getRiepilogoEconomato = (id_societa, stagione) => {
  return services.dbManag
    .post("/economato/societa/movimenti_riepilogo/list", {
      id_societa: id_societa,
      stagione: stagione,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const spostaCrediti = (
  id_societa,
  anno_prov,
  anno_dest,
  importo,
  id_insegnante
) => {
  return services.dbManag
    .post("/economato/societa/sposta-crediti", {
      id_societa,
      anno_prov,
      anno_dest,
      importo,
      id_insegnante,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const stampaMovimentiSocieta = (
  id_societa,
  stagione,
  tipo_mov,
  causale_mov,
  descrizione,
  approvato,
  data_da,
  data_a,
  data_appr_da,
  data_appr_a,
  sortColumn,
  sortOrder
) => {
  const url = `${services.baseUrl}/economato/movimenti/stampa/get?id_societa=${id_societa}&stagione=${stagione}&tipo_mov=${tipo_mov}&causale_mov=${causale_mov}&descrizione=${descrizione}&approvato=${approvato}&data_da=${data_da}&data_a=${data_a}&data_appr_da=${data_appr_da}&data_appr_a=${data_appr_a}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `movimenti_economato_societa.pdf`);
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

export const stampaMovimentiEnte = (
  id_societa,
  stagione,
  tipo_mov,
  causale_mov,
  descrizione,
  approvato,
  data_da,
  data_a,
  data_appr_da,
  data_appr_a,
  sortColumn,
  sortOrder
) => {
  const url = `${services.baseUrl}/economato/movimenti/stampa/get?id_societa=${id_societa}&stagione=${stagione}&tipo_mov=${tipo_mov}&causale_mov=${causale_mov}&descrizione=${descrizione}&approvato=${approvato}&data_da=${data_da}&data_a=${data_a}&data_appr_da=${data_appr_da}&data_appr_a=${data_appr_a}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `movimenti_economato_ente.pdf`);
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};

/* export const stampaMovimentiSocieta = (
  id_societa,
  stagione,
  tipo_mov,
  causale_mov,
  descrizione,
  approvato,
  data_da,
  data_a,
  data_appr_da,
  data_appr_a,
  sortColumn,
  sortOrder
) => {
  return services.downloadFile
    .get("/economato/movimenti/stampa/get", {
      params: {
        id_societa,
        stagione,
        tipo_mov,
        causale_mov,
        descrizione,
        approvato,
        data_da,
        data_a,
        data_appr_da,
        data_appr_a,
        sortColumn,
        sortOrder,
      },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `movimenti_${id_societa}.pdf`);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((e) => {
      return e.response;
    });
}; */

export const convertiMav = (id_persona, id_pagamento, descrizione) => {
  return services.dbManag
    .post("/economato/pagamenti/mav/converti", {
      id_persona,
      id_pagamento,
      descrizione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
