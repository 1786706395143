<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="m-5 pb-2 text-start" v-if="getDetailsEconomatoLoaded && loaded">
      <div class="row">
        <div class="col-md-4 pt-2 pt-2">
          A credito da approvare: {{ creditoApprovare.toFixed(2) }} €
        </div>
        <div class="col-md-4 pt-2">A credito: {{ credito.toFixed(2) }} €</div>
        <div class="col-md-4 pt-2">A debito: {{ debito.toFixed(2) }} €</div>
      </div>
      <div class="row">
        <div class="col-md-4 pt-2">
          Saldo:
          <b :class="saldo >= 0 ? 'text-success' : 'text-danger'"
            >{{ saldo.toFixed(2) }} €</b
          >
        </div>
        <div class="col-md-4 pt-2">Impegnato: {{ impegnato.toFixed(2) }} €</div>
        <div class="col-md-4 pt-2">
          Disponibile: <b>{{ disponibile.toFixed(2) }} €</b>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-4 pt-2">
          Bonus da aggregazione: {{ bonusAffiliazione.toFixed(2) }} €
        </div>
        <div class="col-md-4 pt-2">
          Residuo bonus aggregazione:
          {{ residuoBonusAffiliazione.toFixed(2) }} €
        </div>
      </div> -->
    </div>
    <div class="m-5 pb-2" v-else></div>
    <div class="row justify-content-end">
      <div class="col-md-6 fs-6 text-start">
        <button
          v-if="isEnabled('fnSpostaCrediti')"
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-start me-10"
          data-bs-toggle="modal"
          data-bs-target="#modal_sposta_crediti"
        >
          <i class="bi bi-arrow-left-right fw-bold fs-4"></i>
          Sposta Crediti
        </button>
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 me-10"
          @click="stampaMovimenti()"
        >
          <i class="bi bi-printer fs-6 text-gray-700 me-1"></i>stampa lista
          filtrata
        </button>
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 me-10"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_movimenti_societa"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <ModalEsportaMovimentiSocieta :societa="societa" />
      <div class="col-md-6 fs-6 text-end my-auto">
        <button
          v-if="!readOnly"
          type="button"
          class="badge bg-secondary rounded blueFit me-4 mb-1"
          data-bs-toggle="modal"
          data-bs-target="#modal_add_pagamento"
          @click="resetModalAddPagamento()"
        >
          Introduci pagamento
        </button>
        <button
          v-if="!readOnly && importoSelezionati"
          type="button"
          class="badge bg-secondary rounded blueFit me-4 mb-1"
          :data-bs-toggle="Math.abs(importoSelezionati) > saldo ? 'modal' : ''"
          :data-bs-target="
            Math.abs(importoSelezionati) > saldo
              ? '#modal_add_pagamento_movimenti'
              : ''
          "
          :disabled="disableButton"
          @click="
            setPagaSelezionati();
            resetModalAddPagamentoMovimenti();
          "
        >
          Paga selezionati
        </button>
        <button
          type="button"
          class="badge bg-secondary rounded blueFit me-4 mb-1"
          v-if="!readOnly && !importoSelezionati"
          @click="showPopupSelect()"
        >
          Paga selezionati
        </button>
        <button
          v-if="isEnabled('fnSocietaEconomatoAddMovimento')"
          type="button"
          class="badge bg-secondary rounded blueFit mb-1"
          data-bs-toggle="modal"
          data-bs-target="#modal_add_movimento"
          @click="resetModalAddMovimento()"
        >
          Introduci movimento
        </button>
      </div>
    </div>

    <div class="mx-auto my-auto text-center mt-4" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        ente_economato_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <br />
      <Datatable
        :table-header="tableHeader"
        :table-data="ente_economato_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        :checkedAll="checkAll"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsEconomatoEnte"
        @current-change="setCurrentPageEconomatoEnte"
        @checkboxClicked="
          checkAll ? (checkAll = !checkAll) : (checkAll = true);
          importoSelezionati = 0;
          ente_economato_list.forEach((element) => {
            if (element.pagabile) {
              element.selected = checkAll;
              if (element.selected) {
                importoSelezionati += element.importo;
              }
            }
          });
        "
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <!--    //v-if="!data.pagato" -->
          <input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id"
            v-if="data.pagabile && !readOnly"
            @click="
              checkAll = false;
              data.selected = !data.selected;
              importoSelezionati = 0;
              ente_economato_list.forEach((element) => {
                if (element.pagabile) {
                  if (element.selected) {
                    importoSelezionati += element.importo;
                  }
                }
              });
            "
        /></template>
        <template v-slot:cell-numero="{ row: data }">{{
          data.numero
        }}</template>
        <template v-slot:cell-tipo="{ row: data }">{{ data.tipo }}</template>
        <template v-slot:cell-causale="{ row: data }">{{
          data.causale
        }}</template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long-xl">{{ data.descrizione }}</div></template
        >
        <template v-slot:cell-importo="{ row: data }">
          <div :class="data.importo >= 0 ? 'text-success' : 'text-danger'">
            <div class="text-end">
              {{ data.importo.toFixed(2) }}
            </div>
          </div>
        </template>
        <template v-slot:cell-data_movimento="{ row: data }"
          ><div class="text-center">{{ data.data_movimento }}</div></template
        >
        <template v-slot:cell-data_appr="{ row: data }"
          ><div class="text-center">{{ data.data_appr }}</div></template
        >
      </Datatable>
    </div>

    <SpostaCrediti
      :societa="societa"
      @getDetailsEconomato="getDetailsEconomato"
    />

    <AddMovimento
      @refreshList="refreshList"
      @setSpinnerView="setSpinnerView"
      ref="modalAddMovimento"
      :societa="societa"
    />
    <AddPagamento
      @refreshList="refreshList"
      @setSpinnerView="setSpinnerView"
      ref="modalAddPagamento"
      :societa="societa"
    />
    <AddPagamentoMovimenti
      @refreshList="refreshList"
      @setSpinnerView="setSpinnerView"
      ref="modalAddPagamentoMovimenti"
      :societa="societa"
      :pagaSelezionati="pagaSelezionati"
    />
  </div>
</template>

<script>
import { alertFailed } from "@/composables/swAlert";
import { useStore } from "vuex";
import { ref, computed, watch, onUpdated } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import AddMovimento from "./AddMovimento.vue";
import AddPagamento from "./AddPagamento.vue";
import AddPagamentoMovimenti from "./AddPagamentoMovimenti.vue";
import ModalEsportaMovimentiSocieta from "./ModalEsportaMovimentiSocieta.vue";
import SpostaCrediti from "./SpostaCrediti.vue";
import {
  getRiepilogoEconomato,
  approvaMovimentiMultipla,
  stampaMovimentiSocieta,
  stampaMovimentiEnte,
} from "@/requests/economato";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import useRemoveElement from "@/composables/removeElements";
import isEnabled from "@/composables/isEnabled.js";

import { fromUTCtoISODate } from "@/composables/formatDate";

export default {
  name: "table-economato-societa",
  components: {
    Datatable,
    AddMovimento,
    AddPagamento,
    AddPagamentoMovimenti,
    Loading,
    SpostaCrediti,
    ModalEsportaMovimentiSocieta,
  },
  emits: ["getEconomatoEnteList", "resetFilters"],
  props: {
    societa: {
      type: Object,
      required: false,
    },
    launchSearch: {
      type: Number,
      required: false,
    },
    reset: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    onUpdated(() => useRemoveElement());

    const store = useStore();

    const setFetchRowsEconomatoEnte = (e) => {
      checkAll.value = false;
      store.commit("setFetchRowsEconomatoEnte", e);
      emit("getEconomatoEnteList");
    };
    const setCurrentPageEconomatoEnte = (page) => {
      checkAll.value = false;
      store.commit("setCurrentPageEconomatoEnte", page);
      emit("getEconomatoEnteList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      checkAll.value = false;
      store.commit("setSortColumnEconomatoEnte", columnName);
      store.commit("setSortOrderEconomatoEnte", order);
      emit("getEconomatoEnteList");
    };
    const refreshList = () => {
      checkAll.value = false;
      emit("getEconomatoEnteList");
      getDetailsEconomato(props.societa.id, stagione.value);
    };
    const pagaSelezionati = ref([]);

    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Numero",
        key: "numero",
      },
      {
        name: "Tipo",
        key: "tipo",
      },
      {
        name: "Causale",
        key: "causale",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Importo",
        key: "importo",
        align: "end",
      },
      {
        name: "Data reg.",
        key: "data_movimento",
        align: "center",
      },
      {
        name: "Data appr.",
        key: "data_appr",
        align: "center",
      },
    ]);

    const setPagaSelezionati = () => {
      let cboxes = document.getElementsByName("movimentiSelected");
      let len = cboxes.length;
      pagaSelezionati.value = [];
      for (var i = 0; i < len; i++) {
        if (cboxes[i].checked) {
          pagaSelezionati.value.push(cboxes[i].value);
        }
      }
      if (pagaSelezionati.value.length == 0) {
        Swal.fire({
          html: "Per procedere è necessario selezionare uno o più movimenti",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (Math.abs(importoSelezionati.value) <= saldo.value) {
        Swal.fire({
          title: "Introduzione pagamenti",
          html:
            "Il saldo di " +
            String(Number(saldo.value).toFixed(2)) +
            " € copre totalmente l'importo da pagare<br /><br /><b>Importo:</b> " +
            String(Math.abs(Number(importoSelezionati.value)).toFixed(2)) +
            " €",
          showCancelButton: true,
          confirmButtonText: "Registra pagamento",
          cancelButtonText: "Annulla",
        }).then((result) => {
          if (result.isConfirmed) {
            disableButton.value = true;
            setSpinnerView(true);
            let values = {
              arr_Movimenti: pagaSelezionati.value.join(","),
              id_Societa: props.societa.id,
              importo: importoSelezionati.value,
              anno: stagione.value,
            };
            const responseAdd = approvaMovimentiMultipla(values);
            responseAdd.then((value) => {
              if (value.data.statusCode == 200) {
                Swal.fire({
                  html: "Operazione completata",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                refreshList();
              } else {
                const strMsg = ref(
                  value.data.message
                    ? value.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
                Swal.fire({
                  html: strMsg.value,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
              setSpinnerView(false);
              disableButton.value = false;
            });
          }
        });
      }
    };

    const showPopupSelect = () => {
      alertFailed("Per procedere è necessario selezionare uno o più movimenti");
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    const disponibile = ref(0);
    const creditoApprovare = ref(0);
    const bonusAffiliazione = ref(0);
    const saldo = ref(0);
    const debito = ref(0);
    const credito = ref(0);
    const residuoBonusAffiliazione = ref(0);
    const totalePagare = ref(0);
    const impegnato = ref(0);
    const getDetailsEconomatoLoaded = ref(false);
    const importoSelezionati = ref(0);
    const checkAll = ref(false);
    const disableButton = ref(false);

    const getDetailsEconomato = (id_societa, stagioneCorrente) => {
      getRiepilogoEconomato(id_societa, stagioneCorrente).then((res) => {
        disponibile.value = res.results[0].disponibile;
        creditoApprovare.value = res.results[0].creditoApprovare;
        bonusAffiliazione.value = res.results[0].bonusAffiliazione;
        saldo.value = res.results[0].saldo;
        debito.value = res.results[0].debito;
        credito.value = res.results[0].credito;
        residuoBonusAffiliazione.value =
          res.results[0].residuoBonusAffiliazione;
        //totalePagare.value = res.results[0].totalePagare;
        impegnato.value = res.results[0].impegnato;
        getDetailsEconomatoLoaded.value = true;
      });
    };

    getDetailsEconomato(props.societa.id, stagione.value);

    watch(stagione, () => {
      getDetailsEconomatoLoaded.value = false;
      getDetailsEconomato(props.societa.id, stagione.value);
    });

    watch(
      () => props.launchSearch,
      () => (checkAll.value = false)
    );

    watch(
      () => props.reset,
      (value) => {
        if (value == true)
          getDetailsEconomato(props.societa.id, stagione.value);
      }
    );

    const isLoading = ref(false);
    const fullPage = ref(true);

    const setSpinnerView = (value) => {
      isLoading.value = value;
    };

    const tipo_mov = computed(() => store.getters.tipoEconomatoEnte);
    const causale_mov = computed(() => store.getters.causaleEconomatoEnte);
    const descrizione = computed(() => store.getters.descrizioneEconomatoEnte);
    const approvato = computed(
      () => store.getters.statoApprovazioneEconomatoEnte
    );
    const data_da = computed(() => store.getters.dataRegistDaEconomatoEnte);
    const data_a = computed(() => store.getters.dataRegistAEconomatoEnte);
    const data_appr_da = computed(
      () => store.getters.dataApprovDaEconomatoEnte
    );
    const data_appr_a = computed(() => store.getters.dataApprovAEconomatoEnte);
    const sortColumn = computed(() => store.getters.sortColumnEconomatoEnte);
    const sortOrder = computed(() => store.getters.sortOrderEconomatoEnte);

    const stampaMovimenti = () => {
      console.log(
        props.societa.id,
        stagione.value,
        tipo_mov.value,
        causale_mov.value,
        descrizione.value,
        approvato.value,
        data_da.value,
        data_a.value,
        data_appr_da.value,
        data_appr_a.value,
        sortColumn.value,
        sortOrder.value
      );
      stampaMovimentiSocieta(
        props.societa.id,
        stagione.value,
        tipo_mov.value ? tipo_mov.value : "",
        causale_mov.value ? causale_mov.value : "",
        descrizione.value ? descrizione.value : "",
        approvato.value ? approvato.value : "",
        data_da.value ? fromUTCtoISODate(data_da.value) : "",
        data_a.value ? fromUTCtoISODate(data_a.value) : "",
        data_appr_da.value ? fromUTCtoISODate(data_appr_da.value) : "",
        data_appr_a.value ? fromUTCtoISODate(data_appr_a.value) : "",
        sortColumn.value ? sortColumn.value : "",
        sortOrder.value ? sortOrder.value : ""
      );
    };

    return {
      ente_economato_list: computed(() =>
        store.getters.getStateFromName("resultsente_economato_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedente_economato_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordente_economato_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusente_economato_list")
      ),

      currentPage: computed(() => store.getters.currentPageEconomatoEnte),
      rowsToSkip: computed(() => store.getters.rowsToSkipEconomatoEnte),
      fetchRows: computed(() => store.getters.fetchRowsEconomatoEnte),
      sortColumn: computed(() => store.getters.sortColumnEconomatoEnte),
      sortOrder: computed(() => store.getters.sortOrderEconomatoEnte),
      setFetchRowsEconomatoEnte,
      setCurrentPageEconomatoEnte,
      setSortOrderColumn,
      tableHeader,
      setPagaSelezionati,
      pagaSelezionati,
      refreshList,
      disponibile,
      creditoApprovare,
      bonusAffiliazione,
      saldo,
      debito,
      credito,
      residuoBonusAffiliazione,
      totalePagare,
      getDetailsEconomatoLoaded,
      getDetailsEconomato,
      isLoading,
      fullPage,
      setSpinnerView,
      impegnato,
      importoSelezionati,
      checkAll,
      disableButton,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      showPopupSelect,
      stampaMovimenti,
    };
  },
  methods: {
    resetModalAddPagamento() {
      this.$refs.modalAddPagamento.resetField();
    },
    resetModalAddMovimento() {
      this.$refs.modalAddMovimento.resetField();
    },
    resetModalAddPagamentoMovimenti() {
      this.$refs.modalAddPagamentoMovimenti.resetField();
    },
  },
};
</script>

<style></style>
